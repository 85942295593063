import React from "react";

function FilterSupplyDemand({
  filterName,
  options,
  handleFilterClick,
  label,
  selected,
}) {
  const filterIds = options.map((option, index) => {
    return option;
  });

  return (
    <form id={"accordion"}>
      <h2
        data-toggle="collapse"
        data-target={"#" + filterName}
        aria-expanded="true"
        aria-controls={filterName}
        className={
          filterIds.some((item) => selected.includes(item))
            ? "font-weight-bold h3 card-header-title card-header-title-inputfield"
            : "h3 card-header-title card-header-title-inputfield"
        }
      >
        {label}
      </h2>
      <div id={filterName} className={"collapse show"}>
        {options.map((option) => {
          return (
            <div key={option}>
              <input
                type="checkbox"
                data-target={"#" + option}
                value={option}
                name={option}
                id={option}
                className={"mr-2"}
                onChange={(e) =>
                  handleFilterClick(filterName, option, e.target.checked)
                }
                checked={selected.includes(option)}
              />
              <label htmlFor={option} className={"supply-demand-filter-label"}>
                {option}
              </label>
            </div>
          );
        })}
      </div>
    </form>
  );
}

export default FilterSupplyDemand;
