import React from "react";

function TeaserCompanies({nid, teasers}) {
  return (
    <div key={nid} className={'company-teaser-row col-10 col-lg-12'} dangerouslySetInnerHTML={{__html: teasers[nid]}}/>
  )
}

export default TeaserCompanies;

