import React from "react";

function TeaserSupplyDemand({ nid, teasers }) {
  return (
    <div
      key={nid}
      className={"supply-demand-teaser-row col-12 col-lg-6"}
      dangerouslySetInnerHTML={{ __html: teasers[nid] }}
    />
  );
}

export default TeaserSupplyDemand;
