import React, { useState, useEffect } from "react";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
//dependencies
import ReactPaginate from "react-paginate";
import Loader from "react-loader-spinner";
//components
import TeaserVacature from "./components/TeaserVacature";
import FilterVacatures from "./components/FilterVacatures";

function FilterAppVacature({ fetchDataUrl, entityViewMode }) {
  // SETUP FOR QUERY PARAMETERS
  const pathname = location.pathname; // get pathname from url
  const navigate = useNavigate(); // used to perform action on the url
  const [searchParams] = useSearchParams(); // get search params from url
  const initialPage = parseInt(searchParams.get("page"), 10); // Get the page number from the URL and parse as a base10 int
  const [currentPage, setCurrentPage] = useState(
    isNaN(initialPage) ? 0 : initialPage // if initial page is not a number or not present set it to 0, otherwise use the initial page number
  );
  // SETUP FOR APP
  const itemsPerPage = 10;
  const filterLabels = {
    type: "Type",
    regio: "Regio",
    sector: "Sector",
    statuut: "Statuut",
  };
  //default open or closed
  const filterInitialStatus = {
    type: false,
    regio: false,
    sector: false,
    statuut: false,
  };

  const initialFilters = {
    type:
      searchParams.getAll("type").length > 0 ? searchParams.getAll("type") : [],
    regio:
      searchParams.getAll("regio").length > 0
        ? searchParams.getAll("regio")
        : [],
    sector:
      searchParams.getAll("sector").length > 0
        ? searchParams.getAll("sector")
        : [],
    statuut:
      searchParams.getAll("statuut").length > 0
        ? searchParams.getAll("statuut")
        : [],
  };
  const initialCheckboxes = Object.values(initialFilters).flat();

  // STATE
  const [loading, setLoading] = useState(true);
  const [showNav, setShowNav] = useState(false);
  //  SETUP FOR TEASERS
  const [entities, setEntities] = useState([]);
  const [teasers, setTeasers] = useState([]);
  const [entitiesFiltered, setEntitiesFiltered] = useState([]);
  const [entitiesSliced, setEntitiesSliced] = useState([]);
  const [offset, setOffset] = useState(0);
  //  SETUP FOR FILTERS
  const [filters, setFilters] = useState([]);
  const [activeFilters, setActiveFilters] = useState(initialFilters);
  const [selectedCheckboxes, setSelectedCheckboxes] =
    useState(initialCheckboxes);

  //   const [filtersStatus, setFiltersStatus] = useState(filterInitialStatus);

  //run once on mount
  useEffect(() => {
    //fetch api data
    fetch(fetchDataUrl, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setEntities(data.data);
        setFilters(data.filters);
        setLoading(false);
      });
  }, []);

  //on filter or page update, load changes into url
  useEffect(() => {
    //update filters
    // console.log("useEffect - activeFilters|offset", offset);
    const parameters = {
      type: activeFilters.type,
      regio: activeFilters.regio,
      sector: activeFilters.sector,
      statuut: activeFilters.statuut,
      page: currentPage,
    };

    //create options for navigate
    const options = {
      pathname: pathname,
      search: `?${createSearchParams(parameters)}`,
    };
    //set new url
    navigate(options, { replace: false });
  }, [activeFilters, currentPage]);

  //fetch teasers when filters change
  useEffect(() => {
    fetchTeasers();
  }, [entitiesSliced]);

  //filter entities when filters change
  useEffect(() => {
    filterEntities();
  }, [entities, activeFilters]);

  //set offset when currentPage changes (moved from handlePageClick)
  useEffect(() => {
    setOffset(currentPage * itemsPerPage);
  }, [currentPage]);

  //slice entities when offset changes
  useEffect(() => {
    setEntitiesSliced(
      [...entitiesFiltered].slice(offset, offset + itemsPerPage)
    );
  }, [entitiesFiltered, offset]);

  const fetchTeasers = () => {
    const nids = [...entitiesSliced].map((entity) => {
      return entity.nid;
    });

    fetch("/filter-api/teaser?view_mode=" + entityViewMode, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(nids),
    })
      .then((response) => response.json())
      .then((data) => {
        setTeasers(data);
      });
  };

  const findCommonArrayElements = (array1, array2) => {
    if (array1.length === 0) {
      return true;
    }
    return array1.some((r) => array2.indexOf(r) >= 0);
  };

  const filterEntities = () => {
    let updatedEntities = entities.filter((entity) => {
      let entityShallPass = true;

      Object.keys(activeFilters).forEach((filterKey) => {
        let filterValue = activeFilters[filterKey];

        if (
          filterValue &&
          entity.hasOwnProperty(filterKey) &&
          entity[filterKey] &&
          Array.isArray(entity[filterKey]) &&
          Array.isArray(filterValue) &&
          !findCommonArrayElements(filterValue, entity[filterKey])
        ) {
          entityShallPass = false;
        }
      });
      return entityShallPass;
    });

    setEntitiesFiltered(updatedEntities);
  };

  const handlePageClick = (data) => {
    // window.scrollTo({ top: 0, left: 0 });
    setCurrentPage(data.selected); //updates the page in React-Paginate
  };

  const handleFilterClick = (filterName, option, e) => {
    let updatedSelectedCheckboxes = selectedCheckboxes;
    const updatedFilters = { ...activeFilters };

    if (e) {
      updatedFilters[filterName].push(option);
      updatedSelectedCheckboxes.push(option);
    } else {
      updatedFilters[filterName] = updatedFilters[filterName].filter(
        (e) => option !== e
      );
      updatedSelectedCheckboxes = updatedSelectedCheckboxes.filter(
        (item) => option !== item
      );
    }
    setSelectedCheckboxes(updatedSelectedCheckboxes);
    setActiveFilters(updatedFilters);
    handlePageClick({ selected: 0 }); //reset page to 0 on filter change
  };

  const handleLabelClick = (e) => {};

  const onResetFilter = (e) => {
    e.preventDefault();

    //TODO: Is hack omdat empty filters ergens anders wordt aangepast maar we vinden niet waar
    setActiveFilters({
      type: [], //field_vacature_type
      regio: [], //field_regio
      sector: [], //field_sector
      statuut: [], //field_statuut
    });

    setSelectedCheckboxes([]);
    handlePageClick({ selected: 0 });
  };

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <div className={"row"}>
      <div className={"col-12 col-lg-4"}>
        <button
          className={
            showNav ? "d-none" : "filter-block-toggler btn btn-primary  ml-auto"
          }
          type="button"
          onClick={toggleNav}
        >
          <i className="pr-2 fal fa-sliders-h text-white h3" />
          Filteren
        </button>
        <div className={showNav ? "filter-block show" : "filter-block"}>
          <div className={" d-flex flex-row justify-content-between"}>
            <div className={"d-flex"}>
              <i className="pr-3 fal fa-sliders-h text-primary h3"></i>
              <h3>Verfijn uw selectie</h3>
            </div>
            <span
              className={"btn reset-filter-button text-underline"}
              onClick={onResetFilter}
            >
              Wis filters
            </span>
          </div>
          <div className={"filters"}>
            {filters
              ? Object.keys(filters).map((category, index) => {
                  return (
                    <FilterVacatures
                      key={`FilterVacatures-${category}`}
                      filterName={category}
                      label={filterLabels[category]}
                      options={filters[category]}
                      handleFilterClick={handleFilterClick}
                      selected={selectedCheckboxes}
                      //   filtersStatus={filtersStatus}
                      //   setFiltersStatus={setFiltersStatus}
                    />
                  );
                })
              : "filters are loading"}
          </div>
          <div className="filter-block-footer">
            <button
              className={showNav ? "btn btn-primary" : "d-none"}
              onClick={toggleNav}
            >
              Bekijk resultaten
            </button>
          </div>
        </div>
        <div className="d-none d-lg-block banner banner-with-image">
          <img
            src="https://volta-drupal.s3.eu-central-1.amazonaws.com/bioforum/2025-01/rs19602_img_1250-lpr__1_.jpg?VersionId=f9damVAKHJh1X83qLuzfZKl73SgoJ4LF"
            className={"w-100"}
          />
          <div className="banner-info">
            <h3 className="text-secondary">Personeel gezocht?</h3>
            <a
              href="/geef-hier-je-vacature-in"
              className="read-more no-underline mt-3"
              target="_self"
            >
              <span>Geef hier de vacature in je biobedrijf door!</span>
              <i className="far fa-long-arrow-right" />
            </a>
          </div>
        </div>
      </div>
      <div className={"d-none d-lg-block col-lg-1"}></div>
      <div className={"col-12 col-lg-7 px-4 px-lg-3"}>
        <h1>Vacatures</h1>
        {loading ? (
          <Loader
            type="ThreeDots"
            color="#00937E"
            height={50}
            width={50}
            className="col text-align-center"
          />
        ) : (
          <div className={"row"}>
            {entities &&
              entitiesSliced.map((entity) => {
                return (
                  <TeaserVacature
                    key={entity.nid}
                    nid={entity.nid}
                    teasers={teasers}
                  />
                );
              })}
          </div>
        )}
        <div>
          {!loading && entitiesFiltered.length <= 0 && (
            <div className="text-align-center text-dark">
              Er werden geen resultaten gevonden met de ingestelde filters.
            </div>
          )}
        </div>
        {entitiesFiltered.length / itemsPerPage > 1 && (
          <ReactPaginate
            previousLabel={<i className="fal fa-long-arrow-left"></i>}
            nextLabel={<i className="fal fa-long-arrow-right"></i>}
            breakLabel={"..."}
            pageCount={Math.ceil(entitiesFiltered.length / itemsPerPage)}
            pageRangeDisplayed={itemsPerPage}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            forcePage={currentPage}
            // initialPage={currentPage}
          />
        )}

        <div className="d-lg-none banner banner-with-image mb-0 mt-5">
          <img
            src="https://s3-eu-central-1.amazonaws.com/zapdrupalfilesprod/bioforum/2021-06/RS13050_KVL_20131115_0246-lpr.jpg"
            className={"w-100"}
          />
          <div className="banner-info">
            <h3 className="text-secondary">Personeel gezocht?</h3>
            <a
              href="/geef-hier-je-vacature-in"
              className="read-more no-underline mt-3"
              target="_self"
            >
              <span>Geef hier de vacature in je biobedrijf door!</span>
              <i className="far fa-long-arrow-right" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterAppVacature;
