import React, {useRef, useState, useEffect} from 'react';

import ReactMapboxGl, {Layer, Feature, ScaleControl, ZoomControl, Cluster, Marker, Popup} from 'react-mapbox-gl';

import ClickAwayListener from 'react-click-away-listener';

import 'mapbox-gl/dist/mapbox-gl.css'

const Map = ReactMapboxGl({
  accessToken: 'pk.eyJ1Ijoidm9sdGF3ZWIiLCJhIjoiY2tucjl2c2s1MDE5azJ5bzdrNGd0M3RqYSJ9.i5AVsL6DSap-Q47C7ETm9A',
  dragRotate: false,
  pitchWithRotate: false

});

const styles = {
  cluster: {
    height: '30px',
    width: '30px',
    background: '#00937E',
    borderRadius: '50%',
    color: '#fff',
    textAlign: 'center',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  marker: {
    fontSize: '25px',
    cursor: "pointer",
    color: '#00937E',
  }
};

const mapHeightLarge = 650;
const mapHeightSmall = 310;

const MapComponent = ({entities, centerCoordinates, zoom, onMove, onZoom}) => {

    const [activeEntity, setActiveEntity] = useState({});
    const [viewport, setViewport] = useState(zoom);
    const [center, setCenter] = useState(centerCoordinates);
    const [mapHeight, setMapHeight] = useState(mapHeightSmall);
    const [entityMap, setEntityMap] = useState(null);

    const clusterMarker = (coordinates, pointCount) => (
      <Marker coordinates={coordinates} style={styles.cluster} key={Math.random()}>
        {pointCount}
      </Marker>
    );

    useEffect(() => {
      setCenter(centerCoordinates);
      setViewport(zoom);
    }, [centerCoordinates]);

    useEffect(() => {
      if (entityMap) {
        entityMap.resize();
      }
    }, [mapHeight]);

    const openPopup = (entity) => {
      setActiveEntity(entity);
      setViewport([11]);
      setCenter([entity.lng, entity.lat]);
    }

    const closePopup = () => {
      setActiveEntity({});
    }


    return (
      <>
        <Map style="mapbox://styles/voltaweb/ckpy7vtul0r6h17pau9s4hv92?optimize=true" onStyleLoad={(map) => {
          map.scrollZoom.disable();
          setEntityMap(map);
        }} center={center}
             zoom={viewport}
             onMove={onMove}
             onZoom={onZoom}
             containerStyle={{
               height: mapHeight,
               width: "100%"
             }}>
          <div className="container" style={{position: 'absolute', left: '50%', bottom: '30px', transform: 'translateX(-50%)', 'zIndex': 10}}>
            {mapHeight === mapHeightSmall ?
              <div className={'btn btn-white bg-white text-primary font-weight-bold border-secondary'} onClick={() => setMapHeight(mapHeightLarge)}>Vergroten <i className={'far fa-arrows'}></i></div> :
              <div className={'btn btn-white bg-white text-primary font-weight-bold border-secondary'} onClick={() => setMapHeight(mapHeightSmall)}>Verkleinen <i className={'far fa-compress-alt'}></i></div>
            }
          </div>
          <ScaleControl/>
          <ZoomControl/>
          <Layer type="symbol" id="marker" layout={{'icon-image': 'marker-15'}}>
            <Feature coordinates={[-0.481747846041145, 51.3233379650232]}/>
          </Layer>
          {activeEntity.hasOwnProperty('lat') &&
          <Popup
            coordinates={[activeEntity.lng, activeEntity.lat]}
            onClose={closePopup}
            onBlur={closePopup}
            closeButton={true}
            closeOnClick={true}
          >
            <i class={'far fa-times bbg-popup-close'} onClick={closePopup}></i>
            <a className='node d-flex flex-column px-3 py-1 font-signika text-decoration-none' href={activeEntity.url}>
              <span className="field field--name-title field--type-string field--label-hidden text-primary font-weight-bold h3 mb-2">{activeEntity.title} </span>
              <span className={'text-secondary h4'}>
                Meer informatie <i className={'far fa-long-arrow-right'}></i>
                </span>
            </a>
          </Popup>
          }
          <Cluster ClusterMarkerFactory={clusterMarker} zoomOnClick={true}>
            {
              entities.filter(entity => entity.lng && entity.lat).map(entity => {
                  return (
                    <Marker
                      onClick={() => openPopup(entity)}
                      coordinates={[entity.lng, entity.lat]}
                      key={entity.nid}
                      style={styles.marker}
                      className="creative-marker"
                    >
                      <i className='fas fa-map-marker-alt'></i>
                    </Marker>
                  )
                }
              )
            }
          </Cluster>
        </Map>
      </>
    );
  }
;

export default MapComponent;
