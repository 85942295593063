import React, { useState } from "react";
import { parseTextToValidId } from "./helpers";
import ChildFilter from "./ChildFilter";
import ChildFilterProducts from "./ChildFilterProducts";

function containsTid(obj, tidsToCheck) {
  // Check if the current object's tid is in the list of tids to check
  if (tidsToCheck.includes(obj.tid)) {
    return true;
  }

  // Recursively check children
  const children = obj.children || {};
  return Object.values(children).some((child) =>
    containsTid(child, tidsToCheck)
  );
}

function ParentFilter({
  companyFilter,
  handleFilterClick,
  filterName,
  selected,
}) {
  const [isOpen, setIsOpen] = useState(containsTid(companyFilter, selected));

  function isSectionSelected() {
    const childIds = Object.keys(companyFilter.children).map(
      (productGroupKey, index) => {
        return companyFilter.children[productGroupKey].tid;
      }
    );
    return childIds.some((item) => selected.includes(item));
  }

  return (
    <li>
      <h3
        className={
          isSectionSelected()
            ? `card-header-title ${isOpen ? "" : "collapsed"} font-weight-bold`
            : `card-header-title ${isOpen ? "" : "collapsed"}`
        }
        onClick={() => setIsOpen(!isOpen)}
      >
        {companyFilter.name}
      </h3>
      <div
        id={"tid-" + companyFilter.tid}
        className={isOpen ? "show" : "collapse"}
        // className={}
      >
        {Object.keys(companyFilter.children).map((productGroupKey, index) => {
          let option = companyFilter.children[productGroupKey];

          return (
            <div
              id={"accordion"}
              className={"pl-4 position-relative mb-2"}
              key={index}
            >
              <input
                // data-toggle="collapse"
                // data-target={"#tid-" + option.tid}
                // aria-expanded="true"
                // aria-controls={"tid-" + option.tid}
                type="checkbox"
                className="mr-2 mt-1 "
                value={option.tid}
                id={"optiongroup-" + option.tid}
                name={"tid-" + option.tid}
                onChange={(e) =>
                  handleFilterClick(filterName, option.tid, e.target.checked)
                }
                checked={selected.includes(option.tid)}
              />
              <label
                htmlFor={"optiongroup-" + option.tid}
                data-toggle="collapse"
                data-target={"#tid-" + option.tid}
                aria-expanded="true"
                aria-controls={"tid-" + option.tid}
                className={
                  selected.includes(option.tid)
                    ? "card-header-title card-header-title-inputfield show"
                    : "card-header-title card-header-title-inputfield collapsed"
                }
              >
                {option.name}
              </label>
              <ul
                key={option.tid}
                className={
                  selected.includes(option.tid)
                    ? "list-unstyled pl-4 mt-2 show"
                    : "list-unstyled pl-4 collapse"
                }
                id={"tid-" + option.tid}
              >
                {Object.keys(option.children).map((productKey, index) => {
                  return (
                    <ChildFilterProducts
                      option={option.children[productKey]}
                      key={index}
                      handleFilterClick={handleFilterClick}
                      filterName={filterName}
                      parentId={option.tid}
                      selected={selected}
                    />
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </li>
  );
}

export default ParentFilter;
