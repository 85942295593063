import React, { useState } from "react";
import ChildFilter from "./ChildFilter";

function parseTextToValidId(text) {
  return text
    .trim()
    .replace(/\s+/g, "_")
    .replace("&", "")
    .replace("(", "")
    .replace(")", "")
    .replace("/", "")
    .toLowerCase();
}

// function isSectionSelected(array, check) {
//   return array.some((item) => check.includes(item));
// }

function FilterCompanies({
  filterName,
  options,
  handleFilterClick,
  label,
  selected,
}) {
  const [isOpen, setIsOpen] = useState(isSectionSelected());

  //main item
  function isSectionSelected() {
    const filterIds = Object.keys(options).map((optionKey, index) => {
      return optionKey;
    });

    return filterIds.some((item) => selected.includes(item));
  }

  //subitem
  function isFilterSelected(filterName) {
    return selected.includes(filterName);
  }

  return (
    <form id={"accordion"}>
      <h3
        className={
          isSectionSelected()
            ? `card-header-title ${isOpen ? "" : "collapsed"} font-weight-bold`
            : `card-header-title ${isOpen ? "" : "collapsed"}`
        }
        data-toggle="collapse"
        data-target={"#" + filterName}
        aria-expanded="true"
        aria-controls={filterName}
        onClick={() => setIsOpen(!isOpen)}
      >
        {label}
      </h3>
      <div id={filterName} className={isOpen ? "show" : "collapse"}>
        {Object.keys(options).map((optionKey, index) => {
          return (
            <div
              id={"accordion"}
              className={"pl-4 position-relative"}
              key={index}
            >
              <input
                data-toggle="collapse"
                data-target={"#" + "group-" + parseTextToValidId(optionKey)}
                aria-expanded="true"
                aria-controls={"group-" + parseTextToValidId(optionKey)}
                type="checkbox"
                className="mr-2"
                value={optionKey}
                id={parseTextToValidId(optionKey)}
                name={parseTextToValidId(optionKey)}
                onChange={(e) =>
                  handleFilterClick(filterName, optionKey, e.target.checked)
                }
                checked={selected.includes(optionKey)}
              />
              <label
                htmlFor={parseTextToValidId(optionKey)}
                data-toggle="collapse"
                data-target={"#" + "group-" + parseTextToValidId(optionKey)}
                aria-expanded="true"
                aria-controls={parseTextToValidId("group-" + optionKey)}
                className={
                  Array.isArray(options[optionKey])
                    ? `mb-2 card-header-title card-header-title-inputfield ${
                        selected.includes(optionKey)
                          ? "font-weight-bold"
                          : "collapsed"
                      }`
                    : `${
                        selected.includes(optionKey)
                          ? "font-weight-bold"
                          : "collapsed"
                      }`
                }
              >
                {optionKey}
              </label>
              {Array.isArray(options[optionKey]) ? (
                <ul
                  key={optionKey}
                  className={
                    isFilterSelected(optionKey)
                      ? "list-unstyled pl-4 show"
                      : "list-unstyled pl-4 collapse"
                  }
                  id={"group-" + parseTextToValidId(optionKey)}
                >
                  {options[optionKey].map((subOption, index) => {
                    return (
                      <ChildFilter
                        inputValue={subOption}
                        key={index}
                        handleFilterClick={handleFilterClick}
                        filterName={filterName}
                        parentName={optionKey}
                        selected={selected}
                      />
                    );
                  })}
                </ul>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
    </form>
  );
}

export default FilterCompanies;
