import React, { useState } from "react";

function FilterVacatures({
  filterName,
  options,
  handleFilterClick,
  label,
  selected,

  //   filtersStatus,
  //   setFiltersStatus,
}) {
  const filterIds = Object.keys(options).map((option, index) => {
    return option;
  });

  return (
    <form id={"accordion"}>
      <h2
        data-toggle="collapse"
        data-target={"#" + filterName}
        aria-expanded="true"
        aria-controls={filterName}
        className={
          filterIds.some((item) => selected.includes(item))
            ? "font-weight-bold h3 card-header-title card-header-title-inputfield"
            : "h3 card-header-title card-header-title-inputfield"
        }
        // onClick={handleFilterStatus}
      >
        {label}
      </h2>
      <div id={filterName} className={"collapse show"}>
        {Object.keys(options).map((option) => {
          return (
            <div key={option}>
              <input
                type="checkbox"
                data-target={"#" + option}
                value={option}
                name={option}
                id={options[option].key}
                className={"mr-2"}
                onChange={(e) =>
                  handleFilterClick(filterName, option, e.target.checked)
                }
                checked={selected.includes(option)}
              />
              <label htmlFor={option} className={"supply-demand-filter-label"}>
                {option}
                {/* <span> ({options[option]})</span> */}
              </label>
            </div>
          );
        })}
      </div>
    </form>
  );
}

export default FilterVacatures;
