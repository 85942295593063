import React from "react";
import ParentFilter from "./ParentFilter";

function FilterCompaniesProducts({
  filterName,
  options,
  handleFilterClick,
  selected,
}) {
  function isFilterSelected(filterName) {
    return selected.includes(filterName);
  }

  return (
    <form id={"accordion"}>
      {Object.keys(options).map((subOptionKey, index) => {
        return (
          <ul className={"list-unstyled"} key={index}>
            <ParentFilter
              companyFilter={options[subOptionKey]}
              filterName={filterName}
              handleFilterClick={handleFilterClick}
              selected={selected}
            />
          </ul>
        );
      })}
    </form>
  );
}

export default FilterCompaniesProducts;
