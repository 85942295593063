import { parseTextToValidId } from "./helpers";

function ChildFilter({
  option,
  handleFilterClick,
  filterName,
  parentId,
  selected,
}) {
  return (
    <li>
      <input
        type="checkbox"
        className="mr-2"
        value={option.tid}
        name={"tid-" + option.tid}
        data-parent={parentId}
        id={"tid-" + option.tid}
        onChange={(e) =>
          handleFilterClick(filterName, option.tid, e.target.checked, parentId)
        }
        checked={selected.includes(option.tid)}
      />
      <label htmlFor={"tid-" + option.tid}>{option.name}</label>
    </li>
  );
}

export default ChildFilter;
