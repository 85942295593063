import React, { useState, useEffect } from "react";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
//dependencies
import { debounce } from "throttle-debounce";
import ReactPaginate from "react-paginate";
import Loader from "react-loader-spinner";
import Fuse from "fuse.js";
//components
import TeaserCompanies from "./components/TeaserCompanies";
import FilterCompanies from "./components/FilterCompanies";
import FilterCompaniesProducts from "./components/FilterCompaniesProducts";
import MapComponent from "./components/MapComponent";

function FilterAppCompanies({ fetchDataUrl, entityViewMode }) {
  // SETUP FOR QUERY PARAMETERS
  const pathname = location.pathname; // get pathname from url
  const navigate = useNavigate(); // used to perform action on the url
  const [searchParams] = useSearchParams(); // get search params from url
  const initialPage = parseInt(searchParams.get("page"), 10); // Get the page number from the URL and parse as a base10 int
  const [currentPage, setCurrentPage] = useState(
    isNaN(initialPage) ? 0 : initialPage // if initial page is not a number or not present set it to 0, otherwise use the initial page number
  );

  // SETUP FOR APP
  const itemsPerPage = 10;

  const filterLabels = {
    activity: "Type bedrijf",
    products: "Products",
    region: "Regio",
    certification: "Certificatie",
  };

  const initialFilters = {
    activity:
      searchParams.getAll("activity").length > 0
        ? searchParams.getAll("activity")
        : [],
    region:
      searchParams.getAll("region").length > 0
        ? searchParams.getAll("region")
        : [],
    products:
      searchParams.getAll("products").length > 0
        ? searchParams.getAll("products")
        : [],
    certification:
      searchParams.getAll("certification").length > 0
        ? searchParams.getAll("certification")
        : [],
  };

  const initialFilterStatus = {
    type: false,
    regio: false,
    sector: false,
    statuut: false,
  };

  const fuseOptions = {
    location: 0,
    threshold: 0.2,
    ignoreLocation: true, // default False: True - to search ignoring location of the words.
    findAllMatches: true,
    useExtendedSearch: true,
    keys: ["title", "name_public", "name_zaakvoerder", "company_nr"],
  };
  const initialCheckboxes = Object.values(initialFilters).flat();

  const emptyFilters = {
    activity: [],
    products: [],
    region: [],
    certification: [],
  };

  //STATE
  const [loading, setLoading] = useState(true);
  const [showNav, setShowNav] = useState(false);
  const [zoom, setZoom] = useState([6]); //map zoom level
  const [bounds, setBounds] = useState([]); //initial map bounds
  const [cordinate, setCoordinate] = useState({
    lat: 51.260197,
    lng: 4.402771,
  }); //initial map center coordinate
  const [searchQuery, setSearchQuery] = useState(""); //fusejs query
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  // SETUP FOR TEASERS
  const [entities, setEntities] = useState([]);
  const [teasers, setTeasers] = useState([]);
  const [entitiesFiltered, setEntitiesFiltered] = useState([]);
  const [entitiesSliced, setEntitiesSliced] = useState([]);
  const [offset, setOffset] = useState(0);
  // SETUP FOR FILTERS
  const [filters, setFilters] = useState([]);
  const [activeFilters, setActiveFilters] = useState(initialFilters);
  const [activeFiltersInclParents, setActiveFiltersInclParents] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] =
    useState(initialCheckboxes);
  const [currentProductFilters, setCurrentProductFilters] = useState([]);

  const [filtersStatus, setFiltersStatus] = useState(initialFilterStatus);

  //run once on mount
  useEffect(() => {
    //fetch api data
    fetch(fetchDataUrl, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setEntities(data.data);
        setFilters(data.filters);
        setLoading(false);
      });
  }, []);

  //on filter or page update, load changes into url
  useEffect(() => {
    //update filters
    // console.log("useEffect - activeFilters|offset", offset);
    const parameters = {
      activity: activeFilters.activity,
      region: activeFilters.region,
      products: activeFilters.products,
      certification: activeFilters.certification,
      page: currentPage,
    };

    //create options for navigate
    const options = {
      pathname: pathname,
      search: `?${createSearchParams(parameters)}`,
    };
    //set new url
    navigate(options, { replace: false });
  }, [activeFilters, currentPage]);

  //fetch teasers when filters change
  useEffect(() => {
    fetchTeasers();
  }, [entitiesSliced]);

  //filter entities when filters change
  useEffect(() => {
    filterEntities();
  }, [entities, activeFilters, bounds, searchQuery]);

  //set offset when currentPage changes (moved from handlePageClick)
  useEffect(() => {
    setOffset(currentPage * itemsPerPage);
  }, [currentPage]);

  //slice entities when offset changes
  useEffect(() => {
    setEntitiesSliced(
      [...entitiesFiltered].slice(offset, offset + itemsPerPage)
    );
  }, [entitiesFiltered, offset]);

  const fetchTeasers = () => {
    const nids = [...entitiesSliced].map((entity) => {
      return entity.nid;
    });

    fetch("/filter-api/teaser?view_mode=" + entityViewMode, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(nids),
    })
      .then((response) => response.json())
      .then((data) => {
        setTeasers(data);
      });
  };

  const findCommonArrayElements = (array1, array2) => {
    if (array1.length === 0) {
      return true;
    }
    return array1.some((r) => array2.indexOf(r) >= 0);
  };

  const onResetFilter = (e) => {
    e.preventDefault();

    setEntitiesFiltered([]);

    // setZoom([6]);
    // setCoordinate({lat: 51.260197, lng: 4.402771})

    setSearchQuery("");
    setSelectedCheckboxes([]);

    setOffset(0);

    //TODO: Is hack omdat empty filters ergens anders wordt aangepast maar we vinden niet waar
    setActiveFilters({
      activity: [],
      products: [],
      region: [],
      certification: [],
    });

    // setCurrentProductFilters([]);
    setBounds([]);
    handlePageClick({ selected: 0 });
  };

  const filterEntities = () => {
    let updatedEntities = entities;

    if (bounds.length > 0) {
      //   console.log(bounds);
      updatedEntities = updatedEntities.filter(
        (entity) => entity.lng && entity.lat
      );
    }

    updatedEntities = updatedEntities.filter((entity) => {
      let entityShallPass = true;

      Object.keys(activeFilters).forEach((filterKey) => {
        let filterValue = activeFilters[filterKey];

        // check if filter is not empty and filter on activity is an array
        if (
          filterValue &&
          entity.hasOwnProperty(filterKey) &&
          entity[filterKey] &&
          Array.isArray(entity[filterKey]) &&
          !entity[filterKey].includes(filterValue) &&
          !Array.isArray(filterValue)
        ) {
          entityShallPass = false;
        }

        if (
          filterValue &&
          entity.hasOwnProperty(filterKey) &&
          entity[filterKey] &&
          Array.isArray(entity[filterKey]) &&
          Array.isArray(filterValue) &&
          !findCommonArrayElements(filterValue, entity[filterKey]) &&
          (filterKey !== "activity" ||
            filterKey !== "products" ||
            filterKey !== "certification")
        ) {
          entityShallPass = false;
        }

        if (
          filterValue &&
          entity.hasOwnProperty(filterKey) &&
          entity[filterKey] &&
          Array.isArray(entity[filterKey]) &&
          Array.isArray(filterValue) &&
          !arrayIncludesAllOtherArrayElements(filterValue, entity[filterKey]) &&
          filterKey !== "region"
        ) {
          entityShallPass = false;
        }

        // check if filter is not empty and filter on entity is a string
        if (
          filterValue &&
          entity.hasOwnProperty(filterKey) &&
          entity[filterKey] &&
          (typeof entity[filterKey] === "string" ||
            entity[filterKey] instanceof String) &&
          filterValue !== entity[filterKey]
        ) {
          entityShallPass = false;
        }

        if (
          filterValue &&
          entity.hasOwnProperty(filterKey) &&
          entity[filterKey] === null
        ) {
          entityShallPass = false;
        }

        if (
          filterValue &&
          Array.isArray(filterValue) &&
          filterValue.length > 0 &&
          entity.hasOwnProperty(filterKey) &&
          Array.isArray(entity[filterKey]) &&
          entity[filterKey].length === 0
        ) {
          entityShallPass = false;
        }

        // is filter for mapke
        if (bounds.length > 0 && entity.lat && entity.lng && entityShallPass) {
          entityShallPass =
            entity.lat > bounds[0] &&
            entity.lng > bounds[1] &&
            entity.lat < bounds[2] &&
            entity.lng < bounds[3];
        }
      });
      return entityShallPass;
    });

    if (searchQuery !== "") {
      const fuse = new Fuse(updatedEntities, fuseOptions);
      updatedEntities = fuse
        .search(searchQuery)
        .map((searchResult) => searchResult.item);
    }

    setEntitiesFiltered(updatedEntities);
  };

  const handlePageClick = (data) => {
    // window.scrollTo({ top: 0, left: 0 });
    setCurrentPage(data.selected);
  };

  const boundsChanged = debounce(300, (map) => {
    let mapBounds = map.getBounds();

    const limitedBounds = map.unproject([0, 0]);

    const hDiff = Math.abs(mapBounds.getNorth() - limitedBounds.lat);
    const vDiff = Math.abs(mapBounds.getWest() - limitedBounds.lng);

    mapBounds = [
      mapBounds.getSouth() + hDiff,
      limitedBounds.lng,
      limitedBounds.lat,
      mapBounds.getEast() - vDiff,
    ];

    setBounds(mapBounds);
  });

  const resetSearchQuery = () => {
    setSearchQuery("");
    setIsSearchOpen(false);
    console.log("isSearchOpen", isSearchOpen);
  };

  const arrayIncludesAllOtherArrayElements = (array1, array2) => {
    return array1.every((i) => array2.includes(i));
  };

  const searchEntities = (e) => {
    setSearchQuery(e.target.value);
  };

  //TODO: Parent niet aanzetten als child aangevinkt wordt, dus als je child uitvinkt blijft parent niet aan als deze niet gecheckt is: eventueel moet je eerste niveau aanvinken op dropdown te activeren
  const handleFilterClickV1 = (
    filterName,
    filterValue,
    isCheckboxChecked,
    parentName
  ) => {
    // console.log("handleFilterClick: filterName", filterName);
    // console.log("handleFilterClick: filterValue", filterValue);
    // console.log("handleFilterClick: isCheckboxChecked", isCheckboxChecked);
    // console.log("handleFilterClick: parentName", parentName);
    // debugger;
    let updatedSelectedCheckboxes = selectedCheckboxes;
    const updatedFilters = { ...activeFilters };

    if (isCheckboxChecked) {
      updatedSelectedCheckboxes.push(filterValue);
    } else {
      updatedSelectedCheckboxes = updatedSelectedCheckboxes.filter(
        (item) => filterValue !== item
      );
    }

    setSelectedCheckboxes(updatedSelectedCheckboxes);

    if (filterName === "products" || filterName === "activity") {
      const currentProductFilterValues = currentProductFilters;

      //set checkbox for a parent filter item;
      if (
        isCheckboxChecked &&
        !parentName &&
        !currentProductFilterValues.hasOwnProperty(filterValue)
      ) {
        currentProductFilterValues[filterValue] = [];
        updatedFilters[filterName].push(filterValue);
        console.log("handleFilterClick: updatedFilters1", updatedFilters);
        setActiveFilters(updatedFilters);
        // debugger;
        return;
      }

      if (
        isCheckboxChecked &&
        parentName &&
        currentProductFilterValues.hasOwnProperty(parentName)
      ) {
        currentProductFilterValues[parentName].push(filterValue);
        updatedFilters[filterName] = updatedFilters[filterName].filter(
          (item) => parentName !== item
        );
        updatedFilters[filterName].push(filterValue);
        console.log("handleFilterClick: updatedFilters2", updatedFilters);
        setActiveFilters(updatedFilters);
        // debugger;
        return;
      }

      if (
        isCheckboxChecked &&
        parentName &&
        !currentProductFilterValues.hasOwnProperty(parentName)
      ) {
        currentProductFilterValues[parentName] = [];
        currentProductFilterValues[parentName].push(filterValue);
        updatedFilters[filterName] = updatedFilters[filterName].filter(
          (item) => parentName !== item
        );
        updatedFilters[filterName].push(filterValue);
        console.log("handleFilterClick: updatedFilters3", updatedFilters);
        setActiveFilters(updatedFilters);
        // debugger;
        return;
      }

      if (!isCheckboxChecked && parentName) {
        currentProductFilterValues[parentName] = currentProductFilterValues[
          parentName
        ].filter((item) => filterValue !== item);
        updatedFilters[filterName] = updatedFilters[filterName].filter(
          (item) => filterValue !== item
        );
        if (currentProductFilterValues[parentName].length === 0) {
          updatedFilters[filterName].push(parentName);
        }
        console.log("handleFilterClick: updatedFilters4", updatedFilters);
        setActiveFilters(updatedFilters);
        // debugger;
        return;
      }

      if (
        !isCheckboxChecked &&
        !parentName &&
        currentProductFilterValues[filterValue].length === 0
      ) {
        delete currentProductFilterValues[filterValue];
        updatedFilters[filterName] = updatedFilters[filterName].filter(
          (item) => filterValue !== item
        );
        console.log("handleFilterClick: updatedFilters5", updatedFilters);
        setActiveFilters(updatedFilters);
        // debugger;
        return;
      }
      if (!isCheckboxChecked && !parentName && filterName === "products") {
        Object.values(filters[filterName]).map((filterSubOption, index) => {
          Object.values(filterSubOption.children).map((productGroup, index) => {
            if (productGroup.tid === filterValue) {
              const productIds = Object.values(productGroup.children).map(
                (product) => product.tid
              );

              updatedFilters[filterName] = updatedFilters[filterName].filter(
                (el) => !productIds.includes(el)
              );
              updatedSelectedCheckboxes = updatedSelectedCheckboxes.filter(
                (el) => !productIds.includes(el)
              );
              console.log(
                "setSelectedCheckboxes-products",
                updatedSelectedCheckboxes
              );
              setSelectedCheckboxes(updatedSelectedCheckboxes);
            }
          });
        });
        updatedFilters[filterName] = updatedFilters[filterName].filter(
          (item) => filterValue !== item
        );
        setActiveFilters(updatedFilters);
        // debugger;
        return;
      }

      if (!isCheckboxChecked && !parentName && filterName === "activity") {
        Object.values(filters[filterName]).map((filterSubOption, index) => {
          if (Array.isArray(filterSubOption)) {
            updatedFilters[filterName] = updatedFilters[filterName].filter(
              (el) => filterSubOption.indexOf(el) < 0
            );
            updatedSelectedCheckboxes = updatedSelectedCheckboxes.filter(
              (el) => filterSubOption.indexOf(el) < 0
            );
            console.log(
              "setSelectedCheckboxes-activity",
              updatedSelectedCheckboxes
            );
            setSelectedCheckboxes(updatedSelectedCheckboxes);
          }
        });
        updatedFilters[filterName] = updatedFilters[filterName].filter(
          (item) => filterValue !== item
        );
        setActiveFilters(updatedFilters);
        // debugger;
        return;
      }
    }
    // if filterName is not product or activity
    else {
      if (!isCheckboxChecked) {
        const updatedFilters = { ...activeFilters };
        if (Array.isArray(updatedFilters[filterName])) {
          updatedFilters[filterName] = updatedFilters[filterName].filter(
            (item) => filterValue !== item
          );
        } else {
          updatedFilters[filterName] = "";
        }
        setActiveFilters(updatedFilters);
        // debugger;
        return;
      }
      const updatedFilters = { ...activeFilters };

      if (Array.isArray(updatedFilters[filterName])) {
        updatedFilters[filterName].push(filterValue);
      } else {
        updatedFilters[filterName] = filterValue;
      }
    }

    setActiveFilters(updatedFilters);
    handlePageClick({ selected: 0 }); //reset page to 0 on filter change
  };

  const handleFilterClick = (
    filterName,
    filterValue,
    isCheckboxChecked,
    parentName
  ) => {
    // console.log("handleFilterClick: filterName", filterName);
    // console.log("handleFilterclick: option", filterValue);
    // console.log("handleFilterClick: isCheckboxChecked", isCheckboxChecked);
    // console.log("handleFilterClick: parentName", parentName);

    function filterHasChildren(filterName, filterValue) {
      console.log(filters[filterName]);
      console.log(updatedFilters[filterName]);

      if (filterName === "products") {
        let foundProductGroup = null;

        Object.values(filters[filterName]).forEach((filterSubOption) => {
          Object.values(filterSubOption.children).forEach((productGroup) => {
            if (productGroup.tid === filterValue) {
              foundProductGroup = productGroup;
              return; // Exit the inner loop
            }
          });
          if (foundProductGroup !== null) {
            foundProductGroup = filterSubOption;
            return; // Exit the outer loop if the product group is found
          }
        });
        // if special filter, do this.
        // debugger;
      }

      if (filterName === "activity") {
        // debugger;
      }

      if (filterName === "region" || filterName === "type") {
        // if (
        //   Array.isArray(filters[filterName][filterValue]) &&
        //   filters[filterName][filterValue].length > 0
        // ) {
        //   return filters[filterName][filterValue];
        // } else {
        //   return false;
        // }
        // debugger;
      }
    }
    // function removeChildrenFromFilters(filterName, filterValue) {}

    let updatedSelectedCheckboxes = selectedCheckboxes;
    const updatedFilters = { ...activeFilters };
    const hasChildren = filterHasChildren(filterName, filterValue);
    // debugger;
    if (isCheckboxChecked === true) {
      // if newly checked, add to filters
      updatedFilters[filterName].push(filterValue);
      updatedSelectedCheckboxes.push(filterValue);
    } else {
      //if unchecked, remove items
      if (hasChildren) {
        // remove children
        hasChildren.forEach((child) => {
          // Remove child from filters
          //   debugger;
          console.log(updatedFilters[filterName]);
          updatedFilters[filterName] = updatedFilters[filterName].filter(
            (item) => item !== child
          );
          console.log(updatedFilters[filterName]);
          //   debugger;
          // Remove child from selected checkboxes
          updatedSelectedCheckboxes = updatedSelectedCheckboxes.filter(
            (item) => item !== child
          );
          //   debugger;
        });
      }
      updatedFilters[filterName] = updatedFilters[filterName].filter(
        (isCheckboxChecked) => filterValue !== isCheckboxChecked
      );
      updatedSelectedCheckboxes = updatedSelectedCheckboxes.filter(
        (item) => filterValue !== item
      );
    }

    setSelectedCheckboxes(updatedSelectedCheckboxes);
    setActiveFilters(updatedFilters);
    handlePageClick({ selected: 0 }); //reset page to 0 on filter change
    // debugger;
  };

  function filterHasChildren(filterName, option) {
    console.log(filters);
    console.log(filterName);
    console.log(option);
    const filter = filters[filterName];
    if (!filter) {
      return false; // The filter type does not exist
    }

    const item = filter[option];
    if (!item) {
      return false; // The item with the given ID does not exist
    }

    return item.children && Object.keys(item.children).length > 0;
  }

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <div>
      <div className={"row"}>
        <div className="col-12">
          <MapComponent
            entities={entitiesFiltered}
            centerCoordinates={cordinate}
            zoom={zoom}
            onMove={boundsChanged}
            onZoom={boundsChanged}
          />
        </div>
      </div>
      <div className={"container"}>
        <div className="row mt-5">
          <div className={"col-12 col-lg-4"}>
            <button
              className={
                showNav
                  ? "d-none"
                  : "filter-block-toggler btn btn-primary  ml-auto"
              }
              type="button"
              onClick={toggleNav}
            >
              <i className="pr-2 fal fa-sliders-h text-white h3" />
              Filteren
            </button>
            <div className={showNav ? "filter-block show" : "filter-block"}>
              <div
                className={
                  "filter-header d-flex justify-content-between align-items-start mb-3"
                }
              >
                <div className={"d-flex"}>
                  <i className="pr-3 fal fa-sliders-h text-primary h3"></i>
                  <h3>Verfijn uw selectie</h3>
                </div>
                <span
                  className={"btn reset-filter-button text-underline"}
                  onClick={onResetFilter}
                >
                  Wis filters
                </span>
              </div>
              {Object.keys(filters).map((filter, index) => {
                return filter === "products" ? (
                  <FilterCompaniesProducts
                    key={index}
                    filterName={filter}
                    options={filters[filter]}
                    handleFilterClick={handleFilterClick}
                    selected={selectedCheckboxes}
                  />
                ) : (
                  <FilterCompanies
                    key={index}
                    filterName={filter}
                    options={filters[filter]}
                    handleFilterClick={handleFilterClick}
                    label={filterLabels[filter]}
                    selected={selectedCheckboxes}
                  />
                );
              })}
              <button
                className={
                  showNav
                    ? "btn btn-primary filter-block-footer"
                    : "d-none filter-block-footer"
                }
                onClick={toggleNav}
              >
                Bekijk resultaten
              </button>
            </div>
            <section className="home-iframe d-none text-align-center text-align-lg-left d-lg-flex flex-wrap my-5 p-0">
              <iframe
                id="afb6bddc"
                name="afb6bddc"
                className="iframe-item"
                loading="lazy"
                src="https://leady.elmagroep.nl/www/delivery/afr.php?zoneid=274&target=_blank&ct0=INSERT_ENCODED_CLICKURL_HERE"
                frameBorder={0}
                scrolling="no"
                allowtransparency="true"
              />
              <iframe
                id="2DSFGDFV"
                name="2DSFGDFV"
                className="iframe-item"
                src="https://leady.elmagroep.nl/www/delivery/afr.php?zoneid=274&target=_blank&ct0=INSERT_ENCODED_CLICKURL_HERE"
                frameBorder={0}
                scrolling="no"
                allowtransparency="true"
                loading="lazy"
              />
              <iframe
                id="SDFGZERTEDFG"
                name="SDFGZERTEDFG"
                className="iframe-item"
                loading="lazy"
                src="https://leady.elmagroep.nl/www/delivery/afr.php?zoneid=274&target=_blank&ct0=INSERT_ENCODED_CLICKURL_HERE"
                frameBorder={0}
                scrolling="no"
                allowtransparency="true"
              />
              <iframe
                id="SDFGDFG"
                name="SDFGDFG"
                className="iframe-item"
                loading="lazy"
                src="https://leady.elmagroep.nl/www/delivery/afr.php?zoneid=274&target=_blank&ct0=INSERT_ENCODED_CLICKURL_HERE"
                frameBorder={0}
                scrolling="no"
                allowtransparency="true"
              />
            </section>
          </div>
          <div className={"d-none d-lg-block col-lg-1"}></div>
          <div className={"col-12 col-lg-7"}>
            <div className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-baseline w-100">
              <div
                className={
                  "d-flex justify-content-between align-items-baseline"
                }
              >
                <h1 className={"company-view-title"}>Biobedrijvengids</h1>
                <span className={"ml-4"} style={{ color: "#00937E80" }}>
                  {entitiesFiltered.length} bedrijven
                </span>
              </div>
              <form
                id="search-block-form-companies"
                className="search-form search-block-form form-row"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="search-wrapper px-1 d-flex align-items-center mb-0">
                  <span
                    className="search__icon fa fa-search"
                    onClick={() => setIsSearchOpen(true)}
                  ></span>
                  <div
                    className={
                      isSearchOpen
                        ? "search__field d-flex justify-content-between align-items-center open"
                        : "search__field d-flex justify-content-between align-items-center"
                    }
                  >
                    <input
                      type="text"
                      className={"w-100"}
                      value={searchQuery}
                      onChange={searchEntities}
                      placeholder={"Zoek hier op bedrijf of zaakvoerder"}
                    />
                    <i
                      className="fal fa-times reset"
                      onClick={resetSearchQuery}
                    ></i>
                  </div>
                </div>
              </form>
            </div>

            <a
              className={"mb-5 d-block"}
              target={"_self"}
              href={"/hoe-werkt-de-biobedrijvengids"}
            >
              Hoe werkt de Biobedrijvengids?
            </a>

            <div className={"row justify-content-center"}>
              {loading ? (
                <Loader
                  type="ThreeDots"
                  color="#00937E"
                  height={50}
                  width={50}
                  className="col text-align-center"
                />
              ) : (
                entitiesSliced.map((entity, index) => {
                  return (
                    <TeaserCompanies
                      key={index}
                      nid={entity.nid}
                      teasers={teasers}
                    />
                  );
                })
              )}
              {!loading && entitiesFiltered.length <= 0 && (
                <div className="col-12 text-align-center text-dark">
                  Er werden geen resultaten gevonden met de ingestelde filters.
                </div>
              )}
            </div>
            {entitiesFiltered.length / itemsPerPage > 1 && (
              <ReactPaginate
                previousLabel={<i className="fal fa-long-arrow-left"></i>}
                nextLabel={<i className="fal fa-long-arrow-right"></i>}
                breakLabel={"..."}
                pageCount={Math.ceil(entitiesFiltered.length / itemsPerPage)}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                forcePage={currentPage}
                // initialPage={currentPage}
                // pageRangeDisplayed={2}
                // marginPagesDisplayed={3}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterAppCompanies;
