import {parseTextToValidId} from "./helpers";

function ChildFilter({inputValue, handleFilterClick, filterName, parentName, selected}) {
  return (
    <li>
      <input
        type="checkbox"
        className="mr-2"
        value={inputValue}
        name={parseTextToValidId(parentName + inputValue)}
        data-parent={parentName}
        id={parseTextToValidId(parentName + inputValue)}
        onChange={(e) => handleFilterClick(filterName, inputValue, e.target.checked, parentName)}
        checked={selected.includes(inputValue)}
      />
      <label htmlFor={parseTextToValidId(parentName + inputValue)}>{inputValue}</label>
    </li>
  )
}

export default ChildFilter;
