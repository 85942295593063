export const parseTextToValidId = (text) => {
  return text
    .trim()
    .replace(/\s+/g, "_")
    .replace("&", "")
    .replace("(", "")
    .replace(")", "")
    .replace("/", "")
    .replace(",", "")
    .toLowerCase();
};
