import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
//components
import FilterAppSupplyDemand from "./FilterAppSupplyDemand";
import FilterAppCompanies from "./FilterAppCompanies";
import FilterAppVacature from "./FilterAppVacature";
// const FilterAppVacature = React.lazy(() => import("./FilterAppVacature"));

const filterAppDom = document.getElementById("filter-app");
const filterType = filterAppDom.getAttribute("data-filter");

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

if (filterType == "bioforum-companies") {
  ReactDOM.render(
    <BrowserRouter>
      <FilterAppCompanies
        fetchDataUrl={"/filter-api/companies-data"}
        entityViewMode={"teaser"}
        // itemsPerPage={10}
      />
    </BrowserRouter>,
    filterAppDom
  );
}

if (filterType == "bioforum-supply-demand") {
  ReactDOM.render(
    <BrowserRouter>
      <FilterAppSupplyDemand
        fetchDataUrl={"/filter-api/supply-demand-data"}
        entityViewMode={"teaser"}
        // itemsPerPage={10}
      />
    </BrowserRouter>,
    filterAppDom
  );
}

if (filterType == "bioforum-vacatures") {
  ReactDOM.render(
    <BrowserRouter>
      <FilterAppVacature
        fetchDataUrl={"/filter-api/vacature-data"}
        entityViewMode={"teaser"}
        // itemsPerPage={10}
      />
    </BrowserRouter>,
    filterAppDom
  );
}
